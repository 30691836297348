import "antd/dist/antd.min.css";
import "antd-button-color/dist/css/style.css";

import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Form,
  Layout,
  Button,
  Descriptions,
  Table,
  Card,
  Divider,
  Tabs,
  Input,
  Space,
  BackTop,
  PageHeader,
  Spin,
  Select,
  Typography,
  Tag
} from "antd";

import { SearchOutlined, ClearOutlined } from "@ant-design/icons";

import { createGlobalStyle } from "styled-components";

import moment from "moment";
import "moment/locale/th";

import swal from "sweetalert";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const Reseach_pms = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const DatePicker = generatePicker(dayjsGenerateConfig);

  const [dataSource1, _dataSource1] = useState();
  const [dataSource2, _dataSource2] = useState();
  const [dataSource3, _dataSource3] = useState();

  const [xResearch, _xResearch] = useState("");
  const [xResearchValue, _xResearchValue] = useState("0");

  const [xData, _xData] = useState([]);

  const [pageTable1, _pageTable1] = useState(1);
  const [pageSizeTable1, _pageSizeTable1] = useState(10);
  const [pageTable2, _pageTable2] = useState(1);
  const [pageSizeTable2, _pageSizeTable2] = useState(10);
  const [pageTable3, _pageTable3] = useState(1);
  const [pageSizeTable3, _pageSizeTable3] = useState(10);

  useEffect(() => {
    const token = localStorage.getItem("token");

    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          //ค้นหานักวิจัย
          axios
            .get(xURL + "research_pms")
            .then(result => {
              //console.log(result.data);

              _xResearch(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          /////////////////////////////////////////////////////////////////////////////
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const bClearclick = () => {
    form1.resetFields();

    _pageTable1(1);
    _pageSizeTable1(10);
    _pageTable2(1);
    _pageSizeTable2(10);
    _pageTable3(1);
    _pageSizeTable3(10);

    _xData([]);

    _dataSource1();
    _dataSource2();
    _dataSource3();
  };

  const onFinish = f => {
    // console.log(f);

    if (f.slresearch == undefined) {
      swal("แสดงรายการไม่ได้!", "ไม่มีข้อมูลตามเงื่อนไขที่เลือก!", "warning");
    } else {
      //   console.log(f.slresearch);
      axios
        .post(xURL + "data_pms", {
          research: f.slresearch
        })
        .then(result => {
          _xData(result.data[0]);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });

      axios
        .post(xURL + "data_pms_1", {
          research: f.slresearch
        })
        .then(result => {
          const Tb1 = [];

          result.data.forEach((i, idx) => {
            Tb1.push({
              key: idx,
              num: idx + 1,
              title: i.title,
              roles: i.role_name,
              q_sjr: i.sjr_q,
              journal: i.journal_name,
              year: i.year
            });
          });

          _dataSource1(Tb1);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });

      axios
        .post(xURL + "data_pms_2", {
          research: f.slresearch
        })
        .then(result => {
          const Tb1 = [];

          result.data.forEach((i, idx) => {
            let xtype = "";
            if (i.patent_type == 1) {
              xtype = "อนุสิทธิบัตร";
            } else if (i.patent_type == 2) {
              xtype = "สิทธิบัตร";
            }

            let xdate1 = "",
              xdate2 = "",
              xdate3 = "";

            if (!i.date_input) {
              xdate1 = "";
            } else {
              xdate1 = dayjs(i.date_input).format("DD/MM/BBBB");
            }
            if (!i.date_publish) {
              xdate2 = "";
            } else {
              xdate2 = dayjs(i.date_publish).format("DD/MM/BBBB");
            }
            if (!i.date_expire) {
              xdate3 = "";
            } else {
              xdate3 = dayjs(i.date_expire).format("DD/MM/BBBB");
            }

            Tb1.push({
              key: idx,
              num: idx + 1,
              name: i.patent_name,
              type: xtype,
              date1: xdate1,
              no1: i.patent_request_number,
              no2: i.patent_number,
              date2: xdate2,
              date3: xdate3
            });
          });

          _dataSource2(Tb1);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });

      axios
        .post(xURL + "data_pms_3", {
          research: f.slresearch
        })
        .then(result => {
          const Tb1 = [];

          result.data.forEach((i, idx) => {
            let xsdate = dayjs(i.start_date).format("DD/MM/BBBB");
            let xedate = dayjs(i.end_date).format("DD/MM/BBBB");

            let xbudget = 0;

            if (i.budget > 0) {
              xbudget = i.budget.toLocaleString(undefined, {
                minimumFractionDigits: 2
              });
            }

            Tb1.push({
              key: idx,
              num: idx + 1,
              cmumis_id: i.cmumis_id,
              name_P: i.name_p,
              name_shotP: i.short_name,
              reseatcher_name: i.researname,
              start_date: xsdate,
              end_date: xedate,
              budget: xbudget,
              status: i.status_id,
              type: i.project_type_name
            });
          });

          _dataSource3(Tb1);
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  const columns1 = [
    {
      title: "ลำดับที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 60,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "title",
      key: "title",
      width: 300,
      sorter: (a, b) => {
        return a.title.localeCompare(b.title);
      }
    },
    {
      title: "บทบาท",
      dataIndex: "roles",
      key: "roles",
      align: "center",
      width: 100,
      sorter: (a, b) => {
        return a.roles.localeCompare(b.roles);
      }
    },
    {
      title: "Q(SJR)",
      dataIndex: "q_sjr",
      key: "q_sjr",
      align: "center",
      width: 100,
      sorter: (a, b) => {
        return a.q_sjr.localeCompare(b.q_sjr);
      }
    },
    {
      title: "ชื่อวารสาร",
      dataIndex: "journal",
      key: "journal",
      align: "center",
      width: 200,
      render: (text, record) => __TBShowText(record.journal, 0, 1),
      sorter: (a, b) => {
        return a.journal.localeCompare(b.journal);
      }
    },
    {
      title: "ปีที่พิมพ์",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 100,
      sorter: (a, b) =>
        moment(moment(a.year, "YYYY")).unix() -
        moment(moment(b.year, "YYYY")).unix()
    }
  ];

  const columns2 = [
    {
      title: "ลำดับที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 60,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "ชื่อที่แสดงถึงการประดิษฐ์/การออกแบบผลิตภัณฑ์",
      dataIndex: "name",
      key: "name",
      width: 300,
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      }
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: 100,
      sorter: (a, b) => {
        return a.type.localeCompare(b.type);
      }
    },
    {
      title: "วันที่ยื่นจด",
      dataIndex: "date1",
      key: "date1",
      align: "center",
      width: 100,
      sorter: (a, b) =>
        moment(moment(a.date1, "DD/MM/YYYY")).unix() -
        moment(moment(b.date1, "DD/MM/YYYY")).unix()
    },
    {
      title: "เลขที่คำขอ",
      dataIndex: "no1",
      key: "no1",
      align: "center",
      width: 100,
      sorter: (a, b) => {
        return a.no1.localeCompare(b.no1);
      }
    },

    {
      title: "เลขที่สิทธิบัตร/อนุสิทธิบัตร",
      dataIndex: "no2",
      key: "no2",
      align: "center",
      width: 100,
      sorter: (a, b) => a.no2 - b.no2
    },
    {
      title: "วันที่ออก",
      dataIndex: "date2",
      key: "date2",
      align: "center",
      width: 100,
      sorter: (a, b) =>
        moment(moment(a.date2, "DD/MM/YYYY")).unix() -
        moment(moment(b.date2, "DD/MM/YYYY")).unix()
    },
    {
      title: "วันที่หมดอายุ",
      dataIndex: "date3",
      key: "date3",
      align: "center",
      width: 100,
      sorter: (a, b) =>
        moment(moment(a.date3, "DD/MM/YYYY")).unix() -
        moment(moment(b.date3, "DD/MM/YYYY")).unix()
    }
  ];

  const columns3 = [
    {
      title: "ลำดับที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 60,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "รหัสโครงการ",
      dataIndex: "cmumis_id",
      key: "cmumis_id",
      align: "center",
      width: 100,
      sorter: (a, b) => {
        return a.cmumis_id.localeCompare(b.cmumis_id);
      }
    },
    {
      title: "ชื่อโครงการวิจัย",
      dataIndex: "name_P",
      key: "name_P",
      width: 400,
      sorter: (a, b) => {
        return a.name_P.localeCompare(b.name_P);
      }
    },
    {
      title: "ชื่อย่อโครงการ",
      dataIndex: "name_shotP",
      key: "name_shotP",
      width: 150,
      sorter: (a, b) => {
        return a.name_shotP.localeCompare(b.name_shotP);
      }
    },
    {
      title: "ชื่อหัวหน้าโครงการวิจัย",
      dataIndex: "reseatcher_name",
      key: "reseatcher_name",
      align: "center",
      width: 200,
      sorter: (a, b) => {
        return a.reseatcher_name.localeCompare(b.reseatcher_name);
      }
    },
    {
      title: "วันที่เริ่มต้น",
      dataIndex: "start_date",
      key: "start_date",
      align: "center",
      width: 100,
      sorter: (a, b) =>
        moment(moment(a.start_date, "DD/MM/YYYY")).unix() -
        moment(moment(b.start_date, "DD/MM/YYYY")).unix()
    },
    {
      title: "สิ้นสุด",
      dataIndex: "end_date",
      key: "end_date",
      align: "center",
      width: 100,
      sorter: (a, b) =>
        moment(moment(a.end_date, "DD/MM/YYYY")).unix() -
        moment(moment(b.end_date, "DD/MM/YYYY")).unix()
    },
    {
      title: "งบประมาณ",
      dataIndex: "budget",
      key: "budget",
      align: "center",
      width: 100,
      render: (text, record) =>
        __TBShowText(
          <Text style={{ fontWeight: "bold" }}>
            {record.budget}
          </Text>,
          1,
          0
        ),
      sorter: (a, b) =>
        a.budget.replace(",", "").replace(",", "") -
        b.budget.replace(",", "").replace(",", "")
    },
    {
      title: "สถานะโครงการวิจัย",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
      render: status => {
        let xcolor = "";

        if (status === "P") {
          xcolor = "gold";
        } else if (status === "I") {
          xcolor = "geekblue";
        } else if (status === "C") {
          xcolor = "green";
        } else if (status === "F") {
          xcolor = "red";
        } else if (status === "S") {
          xcolor = "red";
        }

        return (
          <Tag color={xcolor} key={status}>
            {status.toUpperCase()}
          </Tag>
        );
      },
      sorter: (a, b) => {
        return a.status.localeCompare(b.status);
      }
    },
    {
      title: "ประเภทโครงการ",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: 100,
      sorter: (a, b) => {
        return a.type.localeCompare(b.type);
      }
    }
  ];

  function handleChange(value) {
    _xData([]);

    _dataSource1();
    _dataSource2();
    _dataSource3();

    _xResearchValue(value);

    xResearch.forEach((i, idx) => {
      let xname = i.pre + " " + i.name + " " + i.lname,
        xname2 = i.name + " " + i.lname;

      if (i.id === xname2) {
        //console.log(i.name);
        _xResearchValue(xname);
      }
    });
  }

  const pageOnChange1 = (zpage, zpageSize) => {
    _pageTable1(zpage);
    _pageSizeTable1(zpageSize);
  };

  const pageOnChange2 = (zpage, zpageSize) => {
    _pageTable2(zpage);
    _pageSizeTable2(zpageSize);
  };

  const pageOnChange3 = (zpage, zpageSize) => {
    _pageTable3(zpage);
    _pageSizeTable3(zpageSize);
  };

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title="RIHES Performance Management System (RIHES PMS)"
            />
            <Divider />

            <p style={{ fontSize: "18px", fontWeight: "bold" }}>
              รายงานเป้าหมายสมรรถนะการปฏิบัติงาน กลุ่มงาน นักวิจัย/อาจารย์
              สถาบันวิจัยวิทยาศาสตร์สุขภาพ
            </p>
            <Divider />

            <Form onFinish={onFinish} form={form1} initialValues={{}}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item label="นักวิจัย/อาจารย์" name="slresearch">
                    <Select
                      autoFocus
                      showSearch
                      onChange={handleChange}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())}
                    >
                      {xResearch.length > 0
                        ? xResearch.map((i, idx) => {
                            let xname = i.pre + " " + i.name + " " + i.lname,
                              xname2 = i.name + " " + i.lname;

                            if (i.pre == null) {
                              xname = i.name + " " + i.lname;
                            }

                            return (
                              <Option value={xname2}>
                                {xname}
                              </Option>
                            );
                          })
                        : <Option value="0">
                            {}
                          </Option>}
                    </Select>
                  </Form.Item>

                  <Form.Item label="ช่วงเวลา" name="slresearch" />
                </Col>
              </Row>

              <br />
              <Row>
                <Space>
                  <Button ghost type="primary" htmlType="submit">
                    <SearchOutlined /> แสดงข้อมูล
                  </Button>

                  <Button ghost type="danger" onClick={bClearclick}>
                    <ClearOutlined /> เคลียร์
                  </Button>
                </Space>
              </Row>

              <br />
              <Descriptions
                bordered
                size={"small"}
                labelStyle={{ justifyContent: "flex-end", minWidth: 200 }}
              >
                <Descriptions.Item label="ชื่อ-นามสกุล" span={1.5}>
                  {xData.pre} {xData.name} {xData.lname}
                </Descriptions.Item>
                <Descriptions.Item label="ตำแหน่ง" span={1.5}>
                  {xData.position_name}
                </Descriptions.Item>
                <Descriptions.Item label="สังกัด" span={3}>
                  {xData.center_name}
                </Descriptions.Item>
              </Descriptions>

              <br />
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>สมรรถนะงาน</p>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                ตัวชี้วัดที่ 1: ผลงานตีพิมพ์เชิงวิชาการ
              </p>

              <Table
                bordered
                scroll={{ x: "max-content" }}
                dataSource={dataSource1}
                columns={columns1}
                size="small"
                // pagination={false}
                pagination={{
                  onChange: (zpage, zpageSize) => {
                    pageOnChange1(zpage, zpageSize);
                  },
                  current: pageTable1,
                  pageSize: pageSizeTable1
                }}
              />
              <div style={{ backgroundColor: "#fff" }}>
                <span style={{ fontSize: "12px", color: "#748DA6" }}>
                  - Q1=4 คะแนน, Q2=3 คะแนน, Q3=2 คะแนน Q4=1 คะแนน
                </span>

                <br />
                <span style={{ fontSize: "12px", color: "#748DA6" }}>
                  - ถ้าเป็น First/Corresponding Author คูณ 2
                </span>
              </div>

              <br />
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                ตัวชี้วัดที่ 2: ทรัพย์สินทางปัญญา
              </p>

              <Table
                bordered
                scroll={{ x: "max-content" }}
                dataSource={dataSource2}
                columns={columns2}
                size="small"
                // pagination={false}
                pagination={{
                  onChange: (zpage, zpageSize) => {
                    pageOnChange2(zpage, zpageSize);
                  },
                  current: pageTable2,
                  pageSize: pageSizeTable2
                }}
              />
              <div style={{ backgroundColor: "#fff" }}>
                <span style={{ fontSize: "12px", color: "#748DA6" }}>
                  - สิทธิบัตร 4 คะแนน อนุสิทธิบัตรต่างประเทศ 3 คะแนน
                  อนุสิทธิบัตรในประเทศ 2 คะแนน
                </span>

                <br />
                <span style={{ fontSize: "12px", color: "#748DA6" }}>
                  - ถ้าเป็นชื่อแรก คูณ 2
                </span>
              </div>

              <br />
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                ตัวชี้วัดที่ 3: โครงการวิจัยที่ได้รับทุนวิจัย (รวมกันไม่เกิน 8
                คะแนน)
              </p>
              <Table
                bordered
                scroll={{ x: "max-content" }}
                dataSource={dataSource3}
                columns={columns3}
                size="small"
                // pagination={false}
                pagination={{
                  onChange: (zpage, zpageSize) => {
                    pageOnChange3(zpage, zpageSize);
                  },
                  current: pageTable3,
                  pageSize: pageSizeTable3
                }}
              />
              <div style={{ backgroundColor: "#fff" }}>
                <span style={{ fontSize: "12px", color: "#748DA6" }}>
                  - แหล่งทุนต่างประเทศ 4 คะแนน ในประเทศนอกมช. 3 คะแนน ทุนมช. 2
                  คะแนน
                </span>

                <br />
                <span style={{ fontSize: "12px", color: "#748DA6" }}>
                  - ถ้าเป็นหัวหน้าโครงการ คูณ 2
                </span>
              </div>

              <br />
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                ตัวชี้วัดที่ 4: การเรียนการสอน (รวมกันไม่เกิน 8 คะแนน)
              </p>
            </Form>
          </Col>
        </Row>
      </Content>

      <div style={{ height: "100px" }} />

      <Footer_last />
      <BackTop />
    </React.Fragment>
  );
};

export default Reseach_pms;
