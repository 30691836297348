import React, { useState } from "react";

import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";

const Piechart = prop => {
  const COLORS = ["#8884d8", "#9CAFAA", "#ACE2E1", "#67C6E3", "#9BB0C1"];

  return (
    <React.Fragment>
      <ResponsiveContainer width="100%" height={225}>
        <PieChart width={400} height={400}>
          <Pie
            data={prop.dataPie}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={30}
            outerRadius={80}
            paddingAngle={10}
            label={({
              cx,
              cy,
              midAngle,
              innerRadius,
              outerRadius,
              value,
              index
            }) => {
              console.log("handling label?");
              const RADIAN = Math.PI / 180;
              // eslint-disable-next-line
              const radius = 25 + innerRadius + (outerRadius - innerRadius);
              // eslint-disable-next-line
              const x = cx + radius * Math.cos(-midAngle * RADIAN);
              // eslint-disable-next-line
              const y = cy + radius * Math.sin(-midAngle * RADIAN);

              return (
                <text
                  x={x}
                  y={y}
                  fill="#8884d8"
                  textAnchor={x > cx ? "start" : "end"}
                  dominantBaseline="central"
                >
                  {prop.dataPie[index].name} {value} %
                </text>
              );
            }}
          >
            {prop.dataPie.map((entry, index) =>
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            )}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default Piechart;
