import "antd/dist/antd.min.css";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import {
  SaveOutlined,
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { createGlobalStyle } from "styled-components";

import {
  Row,
  Col,
  Form,
  Layout,
  Card,
  Typography,
  Modal,
  Input,
  InputNumber,
  Space,
  Button,
  Table,
  PageHeader,
  Divider
} from "antd";

import moment from "moment";
import "moment/locale/th";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import swal from "sweetalert";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
import thTH from "antd/lib/locale/th_TH";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/es/date-picker/generatePicker";

const { Content } = Layout;
const { Text } = Typography;
const { confirm } = Modal;
const { TextArea } = Input;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const configuredLocale = {
  ...thTH,
  DatePicker: {
    ...thTH.DatePicker,
    dateFormat: "DD MM BBBB", // DD MM BBBB
    yearFormat: "BBBB",
    lang: {
      ...thTH.DatePicker.lang,
      dateFormat: "DD MM BBBB", // DD MM BBBB
      dateTimeFormat: "DD MM BBBB HH:mm:ss",
      yearFormat: "BBBB"
    }
  }
};

dayjs.locale("th");
dayjs.extend(buddhistEra);

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 }
};

const Research_input_installment = () => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }
  const [dataSource1, _dataSource1] = useState([]);

  const [xcmumis_id, _xcmumis_id] = useState("");
  const [xname, _xname] = useState("");
  const [xshortname, _xshortname] = useState("");
  const [xresearch, _xresearch] = useState("");
  const [xdate, _xdate] = useState("");

  const [xterm, _xterm] = useState(1);

  const [xAmout, _xAmout] = useState(0);
  const [xAmoutTh, _xAmoutTh] = useState("");
  const [xSumAmount, _xSumAmount] = useState(0);

  const [xtitle, _xtitle] = useState("");

  const [xEamount, _xEamount] = useState(0);

  const [oamount, _oamount] = useState();
  const [odetail, _odetail] = useState();
  const [oremark, _oremark] = useState();

  const [show1, _show1] = useState(false);

  const txtdetail = useRef("");
  const txtremark = useRef("");

  const DatePicker = generatePicker(dayjsGenerateConfig);

  useEffect(() => {
    const token = localStorage.getItem("token");
    //console.log(token);

    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");
          var url = new URL(window.location.href);

          _xcmumis_id(url.searchParams.get("cmumis_id"));
          _xname(url.searchParams.get("name"));
          _xshortname(url.searchParams.get("shortname"));
          _xresearch(url.searchParams.get("research"));
          _xdate(url.searchParams.get("date"));

          GetTable(url.searchParams.get("cmumis_id"));
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const GetTable = cmumis_id => {
    axios
      .post(xURL + "find_installment", {
        cmumis_id: cmumis_id
      })
      .then(result => {
        // console.log(result);
        const Tb1 = [];
        let xsum = 0;

        result.data.forEach((i, idx) => {
          let xmoney_bath = "",
            xdetail = "",
            xremark = "",
            xdate_warn = "",
            xdate_due = "",
            xdate_receive = "";

          if (i.money_bath != null) {
            xmoney_bath = i.money_bath.toLocaleString(undefined, {
              minimumFractionDigits: 2
            });
          }

          if (i.detail != null) {
            xdetail = i.detail;
          }

          if (i.remark != null) {
            xremark = i.remark;
          }

          if (i.tracking_date && i.tracking_date != "0000-00-00") {
            xdate_warn = dayjs(i.tracking_date).format("DD/MM/BBBB");
          }
          if (i.deadline_date && i.deadline_date != "0000-00-00") {
            xdate_due = dayjs(i.deadline_date).format("DD/MM/BBBB");
          }
          if (i.date_receive && i.date_receive != "0000-00-00") {
            xdate_receive = dayjs(i.date_receive).format("DD/MM/BBBB");
          }

          if (xmoney_bath !== "") {
            Tb1.push({
              key: idx,
              term: idx + 1,
              amount: xmoney_bath,
              detail: xdetail,
              remark: xremark,
              date_warn: xdate_warn,
              date_due: xdate_due,
              date_receive: xdate_receive
            });

            xsum = xsum + parseFloat(i.money_bath);
          }

          _xterm(idx + 2);
        });

        _xSumAmount(
          xsum.toLocaleString(undefined, {
            minimumFractionDigits: 2
          })
        );
        _dataSource1(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bClearclick = () => {
    form1.resetFields();
    form3.resetFields();

    _xAmout(0);
    _xAmoutTh("");

    _xEamount(0);

    _oamount();
    _odetail();
    _oremark();
    _show1(false);
  };

  const bClearclick2 = () => {
    form3.resetFields();

    _xEamount(0);
  };

  const bSaveclick = () => {
    const f = form1.getFieldValue();

    let xdate_warn = "-",
      xdate_due = "-",
      xdate_receive = "-";

    if (f.date_warn) {
      xdate_warn = dayjs(f.date_warn).format("DD/MM/BBBB");
    }
    if (f.date_due) {
      xdate_due = dayjs(f.date_due).format("DD/MM/BBBB");
    }
    if (f.date_receive) {
      xdate_receive = dayjs(f.date_receive).format("DD/MM/BBBB");
    }

    if (!xAmout) {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้คีย์ จำนวนเงิน", "warning");
    } else {
      swal({
        title: "ยืนยันบันทึกเงินงวด",
        text:
          "รหัส CMU MIS " +
          xcmumis_id +
          "\nงวดที่ : " +
          xterm +
          "\nจำนวนเงิน : " +
          xAmout.toLocaleString() +
          " บาท\nวันที่แจ้งเตือน : " +
          xdate_warn +
          "\nวันที่ครบกำหนด : " +
          xdate_due +
          "\nวันที่รับเงิน : " +
          xdate_receive,
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          insertdata();
        }
      });
    }
  };

  const insertdata = () => {
    const f = form1.getFieldValue();
    //console.log(moment(Date()).format('YYYY/MM/DD HH:mm:ss.000'));

    let xdate_warn = "",
      xdate_due = "",
      xdate_receive = "";

    if (f.date_warn) {
      xdate_warn = dayjs(f.date_warn).format("YYYY-MM-DD");
    }
    if (f.date_due) {
      xdate_due = dayjs(f.date_due).format("YYYY-MM-DD");
    }
    if (f.date_receive) {
      xdate_receive = dayjs(f.date_receive).format("YYYY-MM-DD");
    }

    axios
      .post(xURL + "insert", {
        cmumis_id: xcmumis_id,
        amount: parseFloat(xAmout),
        detail: f.eddetail,
        remark: f.edremark,
        date_warn: xdate_warn,
        date_due: xdate_due,
        date_receive: xdate_receive,
        ztime: moment(Date()).format("YYYY/MM/DD HH:mm:ss.000"),
        zorder: 5
      })
      .then(result => {
        bClearclick();
        GetTable(xcmumis_id);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bDeleteClick = (term, amount, detail, remark) => {
    //console.log(amount.replace(",", "").replace(",", ""), detail, remark);

    confirm({
      title: <Text style={{ color: "#003a8c" }}>ยืนยัน ลบการจ่ายเงินงวด</Text>,
      icon: <ExclamationCircleOutlined />,
      content: (
        <Text>
          รหัส CMU MIS : {xcmumis_id}
          <br />
          งวดที่ : {term}
          <br />
          จำนวนเงิน : {amount} บาท
        </Text>
      ),
      okText: "ยืนยัน",
      cancelText: "ยกเลิก",
      onOk() {
        axios
          .post(xURL + "delete", {
            cmumis_id: xcmumis_id,
            amount: amount.replace(",", "").replace(",", ""),
            detail: detail,
            remark: remark,
            xcheck: 11
          })
          .then(result => {
            _xterm(xterm - 1);
            bClearclick();
            GetTable(xcmumis_id);
          })
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });
      }
    });
  };

  const bEditClick = (
    term,
    amount,
    detail,
    remark,
    date_warn,
    date_due,
    date_receive
  ) => {
    _show1(true);

    _xtitle("แก้ไข - รายละเอียดการจ่ายเงิน (งวดที่ " + term + ")");

    _oamount(amount);
    _odetail(detail);
    _oremark(remark);

    _xEamount(amount.replace(",", "").replace(",", ""));

    axios
      .post(xURL + "getDate_install", {
        cmumis_id: xcmumis_id,
        amount: amount.replace(",", "").replace(",", ""),
        detail: detail.substring(0, 8)
      })
      .then(result => {
        let xdate_warn = "",
          xdate_due = "",
          xdate_receive = "";

        if (result.data[0].tracking_date) {
          xdate_warn = dayjs(result.data[0].tracking_date);
        }
        if (result.data[0].deadline_date) {
          xdate_due = dayjs(result.data[0].deadline_date);
        }
        if (result.data[0].date_receive) {
          xdate_receive = dayjs(result.data[0].date_receive);
        }

        form3.setFieldsValue({
          edamount: amount,
          eddetail: detail,
          edremark: remark,
          date_warn: xdate_warn,
          date_due: xdate_due,
          date_receive: xdate_receive
        });
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bclose = () => {
    _show1(false);
  };

  const bEditSclick = () => {
    const f = form3.getFieldValue();

    //console.log(xEamount, f.eddetail, f.edremark);
    let xdate_warn = "",
      xdate_due = "",
      xdate_receive = "";

    if (f.date_warn) {
      xdate_warn = dayjs(f.date_warn).format("YYYY-MM-DD");
    }
    if (f.date_due) {
      xdate_due = dayjs(f.date_due).format("YYYY-MM-DD");
    }
    if (f.date_receive) {
      xdate_receive = dayjs(f.date_receive).format("YYYY-MM-DD");
    }

    if (!xEamount) {
      Modal.info({
        title: "แก้ไข ไม่ได้",
        content: <p>ยังไม่ได้คีย์ จำนวนเงิน</p>,
        onOk() {}
      });
    } else {
      confirm({
        title: (
          <Text style={{ color: "#003a8c" }}>
            ยืนยัน แก้ไขรายละเอียดการจ่ายเงินงวด
          </Text>
        ),
        icon: <ExclamationCircleOutlined />,
        content: "",
        okText: "ยืนยัน",
        cancelText: "ยกเลิก",
        onOk() {
          axios
            .post(xURL + "update", {
              cmumis_id: xcmumis_id,
              amount: xEamount,
              detail: f.eddetail,
              remark: f.edremark,
              oamount: oamount.replace(",", "").replace(",", ""),
              odetail: odetail,
              oremark: oremark,
              date_warn: xdate_warn,
              date_due: xdate_due,
              date_receive: xdate_receive,

              xcheck: 10
            })
            .then(result => {
              _show1(false);
              GetTable(xcmumis_id);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        }
      });
    }
  };

  const columns1 = [
    {
      title: "งวดที่",
      dataIndex: "term",
      key: "term",
      align: "center"
    },
    {
      title: "จำนวนเงิน",
      dataIndex: "amount",
      key: "amount",
      align: "center",

      render: (text, record) => __TBShowText(record.amount, 1, 0)
    },
    {
      title: "รายละเอียด",
      dataIndex: "detail",
      key: "detail",
      width: 550
      //render: (text, record) => __TBShowText(record.detail, 0, 1)
    },
    {
      title: "วันที่แจ้งเตือน",
      dataIndex: "date_warn",
      key: "date_warn",
      align: "center"
    },
    {
      title: "วันที่ครบกำหนด",
      dataIndex: "date_due",
      key: "date_due",
      align: "center"
    },
    {
      title: "วันที่รับเงิน",
      dataIndex: "date_receive",
      key: "date_receive",
      align: "center"
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",

      ellipsis: true,
      width: 350
      //render: (text, record) => __TBShowText(record.remark, 0, 1)
    },

    {
      title: "แก้ไข",
      dataIndex: "update",
      key: "update",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                bEditClick(
                  record.term,
                  record.amount,
                  record.detail,
                  record.remark,
                  record.date_warn,
                  record.date_due,
                  record.date_receive
                );
              }}
            />
          )
        };
      },
      fixed: "right"
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 50,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                bDeleteClick(
                  record.term,
                  record.amount,
                  record.detail,
                  record.remark
                );
              }}
            />
          )
        };
      },
      fixed: "right"
    }
  ];

  const onChangeededamount = e => {
    const THBText = require("thai-baht-text");

    if (e == null) {
      _xAmoutTh("");
      _xAmout();
    } else {
      _xAmoutTh(" (" + THBText(e) + ")");
      _xAmout(e);
    }
  };

  const onChangeEditamount = e => {
    if (e == null) {
      _xEamount(0);
    } else {
      _xEamount(e);
    }
  };

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col span={23}>
            <PageHeader
              className="site-page-header"
              title={
                <a
                  style={{
                    color: "black"
                  }}
                  onClick={() => {
                    window.close();
                  }}
                >
                  วิธีการเบิกจ่าย
                </a>
              }
              onBack={() => window.close()}
            />

            <Divider />

            <Card title="">
              <Form form={form2} {...layout}>
                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>รหัส CMU MIS</Text>
                  }
                >
                  {xcmumis_id}
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>ชื่อโครงการ</Text>
                  }
                >
                  {xname}
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>ชื่อย่อโครงการ</Text>
                  }
                >
                  {xshortname}
                </Form.Item>

                <Form.Item
                  label={
                    <Text style={{ fontWeight: "bold" }}>หัวหน้าโครงการ</Text>
                  }
                >
                  {xresearch}
                </Form.Item>

                <Form.Item
                  label={<Text style={{ fontWeight: "bold" }}>ระยะเวลา</Text>}
                >
                  {xdate}
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>

        <br />

        <Row justify="center">
          <Col span={23}>
            <Card title="รายละเอียดการจ่ายเงิน">
              <Form
                form={form1}
                initialValues={{ edamount: "", eddetail: "", edremark: "" }}
              >
                <Card>
                  <Row>
                    <Col span={5}>
                      <Form.Item label="แบ่งจ่าย งวดที่" name="edterm">
                        <Row>
                          <Input
                            disabled
                            style={{
                              width: 35,
                              color: "red",
                              textAlign: "center"
                            }}
                            value={xterm}
                          />
                        </Row>
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="จำนวนเงิน&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        name="edamount"
                        rules={[
                          {
                            required: true,
                            message: "กรุณากรอก จำนวนเงิน"
                          }
                        ]}
                      >
                        <InputNumber
                          autoFocus
                          style={{ width: 150 }}
                          //disabled={Export}
                          min="0"
                          max="9999999999"
                          maxLength="12"
                          onChange={e => onChangeededamount(e)}
                          formatter={value =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          parser={value => value.replace(/\$\s?|(,*)/g, "")}
                          onPressEnter={() => {
                            txtdetail.current.focus();
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={11}>
                      <Form.Item>
                        <Text style={{ fontWeight: "bold" }}>
                          {xAmoutTh}
                        </Text>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={5} />

                    <Col span={19}>
                      <Form.Item
                        label="รายละเอียด&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        name="eddetail"
                      >
                        <TextArea
                          allowClear
                          showCount
                          rows={3}
                          ref={txtdetail}
                          onPressEnter={() => {
                            txtremark.current.focus();
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={5} />

                    <Col span={19}>
                      <Form.Item
                        label="วันที่แจ้งเตือน&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        name="date_warn"
                      >
                        <DatePicker format="DD/MM/BBBB" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={5} />

                    <Col span={19}>
                      <Form.Item
                        label="วันที่ครบกำหนด&nbsp;&nbsp;&nbsp;&nbsp;"
                        name="date_due"
                      >
                        <DatePicker format="DD/MM/BBBB" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={5} />

                    <Col span={19}>
                      <Form.Item
                        label="วันที่รับเงิน&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        name="date_receive"
                      >
                        <DatePicker format="DD/MM/BBBB" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={5} />

                    <Col span={19}>
                      <Form.Item
                        label="หมายเหตุ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        name="edremark"
                      >
                        <Input allowClear ref={txtremark} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <br />

                  <Row justify="center">
                    <Space>
                      <Button ghost type="primary" onClick={bSaveclick}>
                        <SaveOutlined /> บันทึก
                      </Button>

                      <Button ghost type="danger" onClick={bClearclick}>
                        <ClearOutlined /> เคลียร์
                      </Button>
                    </Space>
                  </Row>
                  <br />
                  <Table
                    bordered
                    scroll={{ x: "max-content" }}
                    dataSource={dataSource1}
                    columns={columns1}
                    size="small"
                    pagination={false}
                    summary={() =>
                      <Table.Summary fixed>
                        <Table.Summary.Row
                          style={{
                            textAlign: "right",
                            backgroundColor: "#b5f5ec"
                          }}
                        >
                          <Table.Summary.Cell>รวมทั้งหมด</Table.Summary.Cell>
                          <Table.Summary.Cell>
                            {xSumAmount.toLocaleString()}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell />
                          <Table.Summary.Cell />
                          <Table.Summary.Cell />
                          <Table.Summary.Cell /> <Table.Summary.Cell />
                          <Table.Summary.Cell /> <Table.Summary.Cell />
                          <Table.Summary.Cell />
                        </Table.Summary.Row>
                      </Table.Summary>}
                  />
                </Card>
              </Form>
              <br />
              {show1
                ? <Form form={form3} initialValues={{}} {...layout}>
                    <Card
                      title={xtitle}
                      extra={
                        <Button ghost type="text" onClick={bclose}>
                          <CloseOutlined />
                        </Button>
                      }
                    >
                      <Form.Item label="จำนวนเงิน" name="edamount">
                        <InputNumber
                          style={{ width: "20%" }}
                          //disabled={Export}
                          min="0"
                          max="9999999999"
                          maxLength="12"
                          onChange={e => onChangeEditamount(e)}
                          formatter={value =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          parser={value => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>

                      <Form.Item label="รายละเอียด" name="eddetail">
                        <TextArea allowClear showCount rows={2} />
                      </Form.Item>

                      <Form.Item label="วันที่แจ้งเตือน" name="date_warn">
                        <DatePicker format="DD/MM/BBBB" />
                      </Form.Item>

                      <Form.Item label="วันที่ครบกำหนด" name="date_due">
                        <DatePicker format="DD/MM/BBBB" />
                      </Form.Item>

                      <Form.Item label="วันที่รับเงิน" name="date_receive">
                        <DatePicker format="DD/MM/BBBB" />
                      </Form.Item>

                      <Form.Item label="หมายเหตุ" name="edremark">
                        <Input allowClear />
                      </Form.Item>

                      <br />

                      <Row justify="center">
                        <Space>
                          <Button ghost type="primary" onClick={bEditSclick}>
                            <SaveOutlined /> แก้ไข
                          </Button>

                          <Button ghost type="danger" onClick={bClearclick2}>
                            <ClearOutlined /> เคลียร์
                          </Button>
                        </Space>
                      </Row>
                    </Card>
                  </Form>
                : <div />}
            </Card>

            <br />
          </Col>
        </Row>
      </Content>

      <Footer_last />
    </React.Fragment>
  );
};
export default Research_input_installment;
